import { twMerge } from "tailwind-merge";
import { Section } from "components/Section";
import { BlockTextCenter } from "components/blocks/BlockTextCenter";
import { TextPoppins } from "@brightstarstudios/components";
import { SectionClip } from "./SectionClip";
import SvgBarren from "assets/svgs/section-clips/barren.svg";
import ImgHyperplayLogo from "assets/images/hyperPlay-badge.png";
import ImgMintleLogo from "assets/images/mintle-badge.png";
import Image from "next/image";

export const SectionPlayOnPartners: React.FC<
  React.HTMLAttributes<HTMLElement>
> = ({ className = "" }) => {
  return (
    <Section
      className={twMerge(
        "relative bg-gradient-to-b from-orange-200 to-primary-red lg:h-[700px]",
        className,
      )}
    >
      <SectionClip
        SvgElement={SvgBarren}
        flipX
        className="absolute inset-x-0 top-2 -translate-y-full fill-orange-200"
      />
      <BlockTextCenter title="ALSO AVAILABLE ON">
        <TextPoppins size="medium" className="mb-8">
          We&apos;re are proud to partner with these amazing platforms, where
          you can explore the game, enjoy seamless gameplay, and engage with
          on-chain elements. Check out our trusted distribution and marketplace
          partners to amplify your Ember Sword experience!
        </TextPoppins>
        <div className="flex justify-center gap-8">
          <a
            href="https://store.hyperplay.xyz/game/embersword"
            target="_blank"
            className="w-64 transition-opacity hover:opacity-85"
          >
            <Image
              src={ImgHyperplayLogo}
              alt="Play on HyperPlay"
              width={563}
              height={143}
            />
          </a>
          <a
            href="https://mintle.app/explore/MANTLE:0x0cc9aa7c7c8f7cee72d5af689587ffbcfb70016d?sort=cheapest"
            target="_blank"
            className="w-64 transition-opacity hover:opacity-85"
          >
            <Image
              src={ImgMintleLogo}
              alt="Trade on Mintle"
              width={563}
              height={143}
            />
          </a>
        </div>
      </BlockTextCenter>
    </Section>
  );
};
