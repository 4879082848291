import type { GetStaticProps, NextPage } from "next";
import { StrapiBlogPost, getAllBlogPosts } from "api/strapi-blog";
import { Header } from "components/globals/Header";
import { Section } from "components/Section";
import { BlockCommunity } from "components/blocks/BlockCommunity";
import { useUser } from "hooks/useUser";
import { SectionNationsAndOwnership } from "components/sections/SectionNationsAndOwnership";
import { SectionLatestNews } from "components/sections/SectionLatestNews";
import { SectionCraftingNewMmorpg } from "components/sections/SectionCraftingNewMmorpg";
import { SectionJoinToday } from "components/sections/SectionJoinToday";
import { SectionWhatIsEs } from "components/sections/SectionWhatIsEs";
import { SectionClip } from "components/sections/SectionClip";
import SvgRobotTrees from "assets/svgs/section-clips/turbine.svg";
import { TempFrontHeroSection } from "components/sections/TempFrontHeroSection";
import { SectionPlayOnPartners } from "components/sections/SectionPlayOnPartners";

interface HomePageProps {
  blogPosts: StrapiBlogPost[];
}

const HomePage: NextPage<HomePageProps> = ({ blogPosts }) => {
  const { user } = useUser();

  return (
    <>
      <Header
        title="Welcome"
        description="Ember Sword is a social sandbox MMORPG taking place in a player-driven universe where the adventure finds you. Join us and become part of our community!"
      />

      <TempFrontHeroSection />

      <SectionLatestNews
        blogPosts={blogPosts}
        className="bg-tertiary-600 fill-tertiary-600 text-white"
      />

      <SectionPlayOnPartners />

      <SectionWhatIsEs />

      <SectionCraftingNewMmorpg />

      <SectionNationsAndOwnership />

      <Section className="relative pt-0">
        <SectionClip
          SvgElement={SvgRobotTrees}
          className="absolute inset-x-0 top-1 -translate-y-full fill-white"
        />
        <BlockCommunity color="light" />
      </Section>

      {!user && <SectionJoinToday />}
    </>
  );
};
export default HomePage;

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  let blogPosts: StrapiBlogPost[] = [];

  try {
    blogPosts = await getAllBlogPosts();

    blogPosts = blogPosts
      .sort((a, b) => {
        if (a.priority && !b.priority) return -1;
        if (!a.priority && b.priority) return 1;
        return 0;
      })
      .slice(0, 5);
  } catch (error) {
    console.error(error);
  }

  return {
    props: {
      blogPosts,
    },
  };
};
