import { twMerge } from "tailwind-merge";
import React from "react";
import { Section } from "components/Section";
import Image from "next/image";
import { Container } from "components/Container";
import mobileBg from "assets/images/hero-mobile.jpg";
import { BlockFullScreenContainer } from "components/blocks/BlockFullScreenContainer";
import { VideoDefferedForSmallScreens } from "components/VideoDesktop";
import Link from "next/link";
import { ButtonAnimated } from "@brightstarstudios/components";
import { useUser } from "hooks/useUser";
import { useAllowedToPlay } from "hooks/useAllowedToPlay";
import { clientConfig } from "config/client";
import SvgEmberswordLogo from "assets/svgs/embersword-logo.svg";
import { getModalRouteLink } from "utils/getModalRouteLink";

const TAILWIND_LG_BREAKPOINT = 1024 as const;

export const TempFrontHeroSection: React.FC<
  React.HTMLAttributes<HTMLElement>
> = ({ className = "", ...rest }) => {
  const { user } = useUser();
  const { data: allowPlay } = useAllowedToPlay();

  const showPlayButton = user && allowPlay && clientConfig.gameLink;

  return (
    <Section className={twMerge("py-0", className)} {...rest}>
      <BlockFullScreenContainer className="relative max-h-[80vh]">
        <div className="absolute inset-0">
          <VideoDefferedForSmallScreens
            loadWidth={TAILWIND_LG_BREAKPOINT}
            muted
            disableRemotePlayback
            disablePictureInPicture
            playsInline
            loop
            className="hidden h-full w-full object-cover object-center lg:block"
          >
            <source src="videos/intro.mp4" type="video/mp4" />
          </VideoDefferedForSmallScreens>

          <Image
            src={mobileBg}
            fill
            sizes="(max-width: 1024px) 100vw, 500px"
            className="block object-cover lg:hidden"
            alt="Background hero image"
            priority
          />
        </div>

        <Container className="relative h-full">
          <div className="flex h-full flex-col items-center lg:items-end lg:justify-center">
            <div>
              <div className="max-w-xs pt-2 md:max-w-md lg:max-w-sm lg:pt-0 xl:max-w-lg 2xl:max-w-xl">
                <SvgEmberswordLogo className="mb-6 h-full w-full text-white" />

                <div className="flex flex-col items-center">
                  <div className="mt-6 flex flex-col gap-4 lg:flex-row">
                    {!user && (
                      <Link href={getModalRouteLink("create-account")}>
                        <ButtonAnimated
                          color="red"
                          label="Register now"
                          className="w-56"
                        />
                      </Link>
                    )}
                    {user && (
                      <a href="https://discord.gg/EmberSword" target="_blank">
                        <ButtonAnimated
                          color="red"
                          label="Join Discord"
                          className="w-56"
                        />
                      </a>
                    )}

                    {!showPlayButton && (
                      <Link
                        href={`/#modal=login&callback=${encodeURIComponent(
                          clientConfig.gameLinkSouthEastAsia,
                        )}`}
                      >
                        <ButtonAnimated
                          color="blue"
                          label="Play"
                          className="w-56"
                        />
                      </Link>
                    )}

                    {showPlayButton && (
                      <Link href={clientConfig.gameLinkSouthEastAsia}>
                        <ButtonAnimated
                          color="blue"
                          label="Play"
                          className="w-56"
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </BlockFullScreenContainer>
    </Section>
  );
};
